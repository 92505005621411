import axios from "axios";

const Api = axios.create({
  // baseURL: "http://192.168.23.15:3003/",
  // baseURL: "http://192.168.23.18:8000/",
  // baseURL: "https://radiato.infocorptus.com/api/",
  baseURL: "https://qa.radiato.infocorptus.com/api/",
  // baseURL: "https://dev.radiato.infocorptus.com/api/",
});

// Request interceptor for adding the bearer token
Api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("auth_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response) {
      if (error.response.status === 403) {
        return Promise.reject(error.response);
      } else {
        return Promise.reject(error.response);
      }
    }
    return Promise.reject(error);
  }
);

export default Api;
